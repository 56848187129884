<template>
  <Title :text="page.title" />
  <div class="grid-column grid-column_span_5">
    <Promo
      v-if="page.image"
      class="promo_full_width"
      :src-pic="page.image.path"
      :src-pic-mobile="page.image.mobile_path"
    />
  </div>

  <div class="grid-column">
    <Share :title="page.title" />
  </div>
  <div class="grid-column grid-column_span_4">
    <div class="h2-1" v-html="page.text"></div>
  </div>

  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column">
    <div class="content-pattern content-pattern_type_1"></div>
  </div>
  <div v-if="page.middle_text" class="grid-column">
    <div v-if="isMobile" class="grid-line"></div>
    <h3 class="h3 upper user-text" v-html="page.middle_text_title"></h3>
  </div>
  <div v-if="page.middle_text" class="grid-column grid-column_span_3">
    <div v-if="isMobile" class="pm-18"></div>
    <div class="content-textarea" v-html="page.middle_text"></div>
    <div v-if="isMobile" class="pm-18"></div>
  </div>
  <div v-if="page.middle_text" class="grid-column"></div>

  <div
    v-if="page.right_text || page.left_text"
    class="grid-column grid-column_mobile_white"
  >
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_top grid-column__inner_position_left"
    >
      <div class="grid-inside">
        <div>
          <div v-if="isMobile" class="pm-18"></div>
          <p>{{ t(`t9`) }}</p>
          <div v-if="isMobile" class="pm-10"></div>
          <div class="button-group2">
            <Button
              class="btn-grey"
              to="https://www.instagram.com/studyarabicru/?igshid=96crythtrzux"
              target="_blank"
              msg="IG"
            />
            <Button
              class="btn-grey"
              to="https://www.facebook.com/studyarabicru/"
              target="_blank"
              msg="FB"
            />
            <Button
              class="btn-grey"
              to="https://vk.com/studyarabicru"
              target="_blank"
              msg="VK"
            />
          </div>
        </div>
        <div v-if="isMobile" class="pm-18"></div>
        <Button
          v-if="page.website"
          class="btn-grey"
          :to="page.website"
          target="_blank"
          :msg="t(`common_website`)"
        />
        <div v-if="isMobile" class="pm-18"></div>
      </div>
    </div>
  </div>
  <div
    v-if="page.right_text || page.left_text"
    class="grid-column grid-column_mobile_white grid-column_span_2"
  >
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_top"
    >
      <div v-if="isMobile" class="pm-18"></div>
      <div class="h3 upper user-text" v-html="page.left_text_title"></div>
      <div v-if="isMobile" class="pm-24"></div>
      <div class="textarea" v-html="page.left_text"></div>
    </div>
  </div>
  <div
    v-if="page.right_text || page.left_text"
    class="grid-column grid-column_mobile_white grid-column_span_2"
  >
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_top grid-column__inner_position_right"
    >
      <div v-if="isMobile" class="pm-10"></div>
      <div v-if="isMobile" class="grid-line"></div>
      <div v-if="isMobile" class="pm-10"></div>
      <div class="h3 upper user-text" v-html="page.right_text_title"></div>
      <div v-if="isMobile" class="pm-24"></div>
      <div class="textarea" v-html="page.right_text"></div>
      <div v-if="isMobile" class="pm-18"></div>
    </div>
  </div>
  <div
    v-if="page.right_text || page.left_text"
    class="grid-column grid-column_mobile_white grid-column_index_up"
  >
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_left grid-column__inner_position_bottom"
    ></div>
  </div>
  <div
    v-if="page.right_text || page.left_text"
    class="grid-column grid-column_mobile_white grid-column_index_up"
  >
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_bottom"
    ></div>
  </div>
  <div
    v-if="page.right_text || page.left_text"
    class="grid-column grid-column_mobile_white grid-column_index_up"
  >
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_bottom"
    ></div>
  </div>
  <div
    v-if="page.right_text || page.left_text"
    class="grid-column grid-column_mobile_white grid-column_index_up"
  >
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_bottom"
    ></div>
  </div>
  <div
    v-if="page.right_text || page.left_text"
    class="grid-column grid-column_mobile_white grid-column_index_up"
  >
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_bottom grid-column__inner_position_right"
    ></div>
  </div>

  <News />
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import en from "@/locales/en.json";
import ru from "@/locales/ru.json";
import ar from "@/locales/ar.json";

import { GetPageId } from "@/api/Pages";

import Title from "@/components/Title.vue";
import Promo from "@/components/Promo.vue";
import Share from "@/components/Share.vue";
import News from "@/components/News.vue";
import Button from "@/components/Button.vue";

export default defineComponent({
  name: "Article",
  components: {
    Title,
    Promo,
    Share,
    News,
    Button,
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
      messages: {
        en,
        ru,
        ar,
      },
    });

    return { t, locale };
  },
  watch: {
    locale() {
      this.fetchData();
    },
    $route() {
      this.fetchData();
    },
  },
  data() {
    return {
      page: {},
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.$progress.start();
      try {
        const { id } = this.$route.params;
        const resp = await GetPageId(id);
        const page = resp.data.data;

        this.page = page;
        this.$progress.finish();
      } catch (error) {
        this.$progress.finish();
        console.log(error);
      }
    },
  },
});
</script>

<style lang="scss" scoped></style>

<i18n>
{
  "en": {
    "t9": "Follow us:",
  },
  "ru": {
    "t9": "Мы в социальных сетях:",
  },
  "ar": {
    "t9": "تجدوننا على مواقع التواصل الاجتماعي",
  }
}
</i18n>
